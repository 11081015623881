import { Component, OnInit, ViewChild, OnDestroy, ViewChildren, ElementRef } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { User } from "../../login/user-management.service";
import { BullListRecallService } from "../bull-list-recall.service";
import { BullService } from "../../bull/bull.service";
import { Subject, Subscription } from "rxjs";
import { BullFormDataService, NaabCodeIndexBull } from "./bull-form-data.service";
import { BullForm, Trait, ProductLine } from "./bull-form.model";
import { MarketingCampaign, Categories, HealthTest } from "./bull-form.model";
import { MarketingGroup } from "./bull-form.model";
import { AlertService } from "../../utils/alerts/alert.service";
import { LactationField } from "../bull-list.model";
import { AuthService } from "../../auth/auth.service";
import { AppService } from "../../app.service";
import { StaticAssetsUrlBase } from "../../../environments/environment";
import { ProgressBarService } from "../../utils/loading/progress-bar.service";

interface Field {
	fieldName: string;
	fieldValue: any;
}

interface EditFields {
	fieldsToEdit: Field[];
}

interface EditableField {
	name: string;
	editing: boolean;
}

/**
* Still not the best solution to the magic strings representing trait categories, but runtime constants are still better.
**/
enum CategoryMap {
	Uncategorized = "Uncategorized",
	Production = "Production",
	Indexes = "Indexes",
	Wellness = "Wellness Traits",
	SireFertility = "Sire Fertility",
	DaughterFertility = "Daughter Fertility",
	Management = "Management",
	Type = "Type",
	Inbreeding = "Inbreeding",
	Lactation = "Lactation"
}


@Component({
	selector: "modal-page",
	templateUrl: "./bull-form-modal.component.html",
	styleUrls: ["./bull-form-modal.component.scss"],
})
export class BullFormModalPage implements OnInit, OnDestroy {
	availableStuds: string[] = [];
	preSavedNaabCodes: string[] = [];
	healthTestOrder = ["IBR", "LEU", "BT", "EHD", "JOHNES", "JOHNES-FECAL", "UDT"]; // TODO: Get this from client meta when it's available.
	ignoredBadges: string[] = ["Japan", "Saudi", "WWSBulls", "Interim", "NewToLineup", "ActiveLineup", "LowPriced"];
	ignoredMarketingGroups: string[] = ["WWSBulls", "Interim", "NewToLineup", "ActiveLineup", "LowPriced"];
	additionalMarketingRights: MarketingGroup[];
	proofPeriodString: String = "";
	search: string;
	@ViewChildren("naabCodes") naabCodesToReorder;
	@ViewChild("matchItemContainer") matchContainer: ElementRef;
	editMode: boolean = false;
	startingBull: string;
	currentBull: string;
	searchIndex: number = 0;
	searchShortName: string = "";
	searchRegId: string = "";
	bull: BullForm;
	documents: any;
	responseBull: BullForm;
	bullData;
	removableMarketingGroups: MarketingGroup[] = [];
	editedMarketingGroups: MarketingGroup[] = [];
	editedMarketingRights: MarketingGroup[] = [];
	deepCopyMarketingStatus: MarketingGroup;
	injectedMarketingStatus: boolean;
	productionTabCategories: Categories[];
	fertilityTabCategories: Categories[];
	type: Categories;
	typeTraitsArrayOne: Trait[];
	typeTraitsArrayTwo: Trait[];
	wellness: Categories;
	productionTraits: Trait[]
	compositeTraits: Trait[]
	wellnessCDCB: Trait[];
	wellnessZoetis: Trait[];
	wellnessZoetisTwo: Trait[];
	Uncategorized: Categories;
	management: Categories;
	inbreeding: Trait[];
	managementTraitsArrayOne: Trait[];
	managementTraitsArrayTwo: Trait[];
	health: HealthTest[];
	deepCopy: BullForm;
	marketingGroups: MarketingGroup[];
	designationList: MarketingCampaign[];
	marketingRights: MarketingGroup[];
	marketingRightsActions: string[] = ["Shipped", "Offered", "Ordered", "Listed", "DNQ"];
	indexList: MarketingCampaign[];
	traitMeta: any[] = [];
	productMeta: any[] = [];

	newNaabCode: string;

	// TODO: Pull this from the meta and map it here if the TestResult field is truly needed.
	healthTestMeta = [];
	animalMeta = [];
	Alias?: string;
	Id?: string;
	TestDate?: string;
	TestName?: string;
	TestResult?: string;
	LegacyName?: string;

	marketingStatus: MarketingGroup = {
		ExpireDate: "",
		ActivateDate: "",
		Name: "",
		Type: "MarketingGroup",
		Label: ""
	};

	currentTab: string = "General Information";
	nextBull: Subject<BullForm> = new Subject();
	nextBullSub: Subscription;
	saveChangesSubscription: Subscription;
	prices: boolean = false;
	productLineArray = [];
	dateOfBirthString: string;

	fullMarketingGroupsList: MarketingGroup[] = [];
	additionalMarketingGroups: MarketingGroup[] = [];
	possibleMatches: NaabCodeIndexBull[] = [];
	userCanEdit: boolean = false;

	public marketingLogoMap: any[] = [];

	public bullLogoMeta: any = {};

	public bullParentAverages = [];

	private LactationMeta: any;
	evalDateGenomic: string = "";


	constructor(
		private modalController: ModalController,
		public authService: AuthService,
		public formData: BullFormDataService,
		public recall: BullListRecallService,
		public bullService: BullService,
		private alert: AlertService,
		private appService: AppService,
		private progress: ProgressBarService
	) {
		this.fullMarketingGroupsList = this.bullService.applicationMeta.MarketingGroup.sort((m1, m2) => {
			return (m1.Name < m2.Name ? -1 : m1.Name > m2.Name ? 1 : 0);
		});
		if (this.recall.showBullFormFromList) {
			if (this.recall.clickedbull !== "") {
				this.startingBull = this.recall.clickedbull;
				this.searchIndex = this.recall.clickedbullIndex;
				this.recall.showBullFormFromList = false;
			} else {
				this.startingBull = this.GrabRandomFromLineup();
				this.recall.showBullFormFromList = false;
			}
		}
		else if (this.recall.currentBullsList.length > 0) {
			this.startingBull = this.recall.currentBullsList[0].PrimaryNaabCode;
		}
		else {
			this.startingBull = this.GrabRandomFromLineup();
		}

		// Gets the Health Test meta from the App Service.
		this.LoadHealthTestMeta();

		// Gets the Animal Meta from the App Service.
		appService.GetAnimalMetaMetadata().then(animalMeta => {
			this.animalMeta = animalMeta;
		});

		// Gets the Product Logo Meta from the App Service.
		appService.GetProductLogoMetadata().then(productLogoMeta => {
			this.marketingLogoMap = productLogoMeta
				.filter(meta => meta.IsPublished)
				.map(meta => {
					return {
						MfgCode: meta.Stud,
						Logo: StaticAssetsUrlBase.concat("/logos/product_logos/", meta.Logo),
						Label: meta.Label
					}
				}
			);
		});

		// Flatten the Proof meta into an array of traits from the app service.
		appService.GetProofMetadata().then(proofMeta => {
			proofMeta.forEach(category => {
				if (category.Country == "USA") {
					this.traitMeta = [...this.traitMeta, ...category.Traits];
				}
			});
		});

		// Flatten the Lactation meta into an array of traits from the app service.
		appService.GetLactationMetadata().then(lact => {
			lact.forEach(category => {
				this.LactationMeta = lact;
			});
		});

		// Fetch the product line metadata.
		appService.GetProductLineMetadata().then(plm => {
			this.productMeta = plm;
		});
	}


	/**
	 * Gets the Health Test meta from the App Service.
	 */
	private LoadHealthTestMeta() {
		this.appService.GetHealthTestMetadata().then(healthTestMeta => {
			this.healthTestMeta = healthTestMeta.filter(meta => meta.IsPublished).map(meta => {
				meta.LegacyName = meta.LegacyName.toUpperCase();
				meta.TestResult = "";
				return meta;
			});
		});
	}


	/**
	 * It's been bugging me ever since Jedi was "retired".
	 * Now we're pulling a random from the salable lineup to populate the bull form on entry.
	 * @returns a Reg Id that will populate the bull form with a default bull at random.
	 */
	private GrabRandomFromLineup(): string {
		const potentials = this.formData.naabCodeIndex.filter(item => item.Priority == 0 && item.BreedGroup == "Dairy" && item.NaabCodes.length > 2);
		const rx: number = Math.floor(Math.random() * potentials.length);
		// console.log([potentials.length, rx, potentials[rx]]);
		return potentials[rx].RegId;
	}

	naabCodeSelected(event: any) {
		this.newNaabCode = event.value;
	}

	addNaabCode() {
		this.bull.ProductLines.push({ "Id": this.bull.Id, "NaabCode": this.newNaabCode, "Published": false });
		this.deepCopy.ProductLines.push({ "Id": this.bull.Id, "NaabCode": this.newNaabCode, "Published": false, "DisplayOrder": this.deepCopy.ProductLines.length });
		this.preSavedNaabCodes.push(this.newNaabCode);
		this.getAvailableStudCodes();
	}

	removeNaabCode(naabcode: string) {
		this.bull.ProductLines = this.bull.ProductLines.filter(el => el["NaabCode"] != naabcode);
		this.deepCopy.ProductLines = this.deepCopy.ProductLines.filter(el => el["NaabCode"] != naabcode);
	}

	getAvailableStudCodes() {
		let bullStudCodes = this.bull.ProductLines.map(el => el["NaabCode"].replace(/([^0-9])\w+/,''));
		let activeStuds = ["7","507","509","14","614","814","250","550","559","9","579","2","289","585","514","557","714","744","644"];
		this.availableStuds = activeStuds.filter(el => bullStudCodes.indexOf(el) < 0);
	}

	handleReorder(event: any) {
		event.detail.complete();

		for (let i = 0; i < event.target.children.length; i++) {
			for (let product of this.bull.ProductLines) {
				if (product.NaabCode === event.target.children[i].innerText) {
					product.DisplayOrder = i;
				}
			}
		}
	}

	checkForBlocked(prodLine: any) {
		let block;
		for (let i = 0; i < prodLine.length; i++) {
			if (prodLine[i][0] === "Blocked" && prodLine[i][1]) {
				block = { border: "5px solid red" };
			}
		}
		if (!block) {
			block = {
				border: "none",
			};
		}

		return block;
	}

	checkItemCard(prodLine: any) {
		let valid = false;
		for (let i = 0; i < prodLine.length; i++) {
			if (prodLine[i][0] === "HasItemCard" && prodLine[i][1]) {
				valid = true;
			}
		}
		return valid;
	}
	changeDeadStatus() {
		this.bull.IsDead = !this.bull.IsDead;
	}
	setProductionStatus() {
		let status: string = "";
		this.bull.ProductionStatus?.IsEU ? (status = status + "EU-") : null;
		this.bull.ProductionStatus?.IsIsolation ? (status = status + "Isolation") : null;
		this.bull.ProductionStatus?.IsProduction ? (status = status + "Production") : null;
		this.bull.ProductionStatus?.IsLayoff ? (status = status + "Layoff") : null;
		this.bull.BullProductionStatus = status;
	}
	findPossibleMatches(id: string, event: any) {
		if (event.code !== "Enter") {
			let searchTimer = setTimeout(() => {
				this.possibleMatches = [];
				let parsedNumberA: number;
				let parsedNumberB: number;
				let isNaabCodeSearch = id.match(/^([0-9]{1,3})([a-zA-Z]{1,2})/);
				id = id.toUpperCase().trim();
				if (id.length > 2) {
					// NAABCODE SEARCHING
					if (isNaabCodeSearch) {
						let searchNaabCodes = this.formData.naabCodeIndex.filter((bullIndex) => {
							return bullIndex?.NaabCodes?.length ? length > 0 : null;
						});
						let foundNaabCodes = searchNaabCodes
							.filter((bull) => {
								let naabs = bull?.NaabCodes?.filter((naab) => {
									return naab.match(`^${id}`);
								});

								return naabs?.length ? length > 0 : null;
							})
							.map((filteredBull) => {
								let matchedNaab = filteredBull?.NaabCodes?.filter((naab) => {
									return naab.match(`^${id}`);
								})[0];
								filteredBull.displayNaabCode = matchedNaab;
								return filteredBull;
							});

						if (foundNaabCodes.length > 0) {
							for (let i = 0; i < 10; i++) {
								if (foundNaabCodes[i]) {
									this.possibleMatches.push(foundNaabCodes[i]);
								}
							}
						}
					} else {
						// REGID SEARCHING

						let searchRegId = this.formData.naabCodeIndex.filter((bullIndex) => {
							return bullIndex.RegId !== null && bullIndex.RegId !== "" && bullIndex.NaabCodes.length > 0;
						});
						let foundRegIds = searchRegId.filter((bullIndex) => {
							return bullIndex?.RegId?.match(`^${id}`);
						});
						if (foundRegIds.length > 0) {
							for (let i = 0; i < 10; i++) {
								if (foundRegIds[i]) {
									this.possibleMatches.push(foundRegIds[i]);
								}
							}
							this.possibleMatches = this.possibleMatches.map((match) => {
								match.displayNaabCode = match.NaabCodes[0];
								return match;
							});
						} else {
							//SHORTNAME SEARCHING
							let searchShortNames = this.formData.naabCodeIndex.filter((bullIndex) => {
								return bullIndex.ShortName !== null && bullIndex.ShortName !== "" && bullIndex.NaabCodes.length > 0;
							});

							let foundShortNames = searchShortNames.filter((bullIndex) => {
								return bullIndex.ShortName.match(`^${id}`);
							});
							if (foundShortNames.length > 0) {
								for (let i = 0; i < 10; i++) {
									if (foundShortNames[i]) {
										this.possibleMatches.push(foundShortNames[i]);
									}
								}
								this.possibleMatches = this.possibleMatches.map((match) => {
									if (match.NaabCodes[0]) {
										match.displayNaabCode = match.NaabCodes[0];
									}

									return match;
								});
							}
						}
					}

					// Sorting is now handled solely by the natural order of the NaabCodeIndex.
				}
				this.matchContainer.nativeElement.style.border = this.possibleMatches.length === 0 ? "none" : "1px solid black";
			}, 500);
		}
	}
	addMarketingGroup(dist: string) {
		this.editedMarketingGroups.push({
			Name: dist,
			Type: "MarketingGroup",
			ActivateDate: new Date(Date.now()).toLocaleDateString(),
			ExpireDate: null,
		});
		this.marketingGroups.push({
			Name: dist,
			Type: "MarketingGroup",
			ActivateDate: new Date(Date.now()).toLocaleDateString(),
			ExpireDate: null,
		});
		this.additionalMarketingGroups = this.fullMarketingGroupsList
			.filter((mk) => {
				return mk.Name === "Japan" || mk.Name === "Saudi";
			})
			.filter((mktgrp) => {
				return !this.marketingGroups.map((mk) => mk.Name).includes(mktgrp.Name);
			});
		this.removableMarketingGroups = this.marketingGroups.filter((mkt) => mkt.Name === "Japan" || mkt.Name === "Saudi");
	}
	removeMarketingGroup(dist: string) {
		let groupToRemove = this.marketingGroups.map((mk) => mk.Name).indexOf(dist);
		this.marketingGroups.splice(groupToRemove, 1);
		this.editedMarketingGroups.push({
			Name: dist,
			Type: "MarketingGroup",
			ActivateDate: null,
			ExpireDate: new Date(Date.now()).toLocaleDateString(),
		});

		this.additionalMarketingGroups = this.fullMarketingGroupsList
			.filter((mk) => {
				return mk.Name === "Japan" || mk.Name === "Saudi";
			})
			.filter((mktgrp) => {
				return !this.marketingGroups.map((mk) => mk.Name).includes(mktgrp.Name);
			});
		this.removableMarketingGroups = this.marketingGroups.filter((mkt) => mkt.Name === "Japan" || mkt.Name === "Saudi");
		this.additionalMarketingGroups;
	}

	getMarketingStatus() {
		if (this.marketingStatus.Name === "NewToLineup") {
			return "New";
		} else {
			return this.marketingStatus.Name;
		}
	}
	getMarketingRightDate(dist: string, whichDate: string) {
		let mk = this.bull.MarketingGroups.filter((mktr) => mktr.Type === "MarketingRight" && mktr.Name === dist)[0];
		switch (whichDate) {
			case "start":
				return mk.ActivateDate;
			case "end":
				return mk.ExpireDate;
		}
	}
	getMarketingStatusDate(type: string) {
		if (type === "start") {
			if (this.marketingStatus.ActivateDate === "") {
				return this.marketingStatus.ActivateDate;
			} else {
				return new Date(this.marketingStatus.ActivateDate);
			}
		} else {
			if (this.marketingStatus.ExpireDate === "") {
				return this.marketingStatus.ExpireDate;
			} else {
				return new Date(this.marketingStatus.ExpireDate);
			}
		}
	}
	// getReleaseDate() {
	// 	if (this.bull.ReleaseDateString === "") {
	// 		return this.bull.ReleaseDateString;
	// 	} else {
	// 		return new Date(this.bull.ReleaseDate);
	// 	}
	// }
	numberOnlyInputCheck(event: any) {
		let inputNumberRegEx = /[A-Za-z\.\"\(\)\[\]\{\}\$\@\#\%\^\&\*\!\;\:\|]/;
		let inputCharCode = String.fromCharCode(event.charCode);
		if (!inputCharCode.search(inputNumberRegEx)) {
			event.preventDefault();
		}
	}
	removeMarketingRight(right: MarketingGroup) {
		right.ExpireDate = new Date(Date.now()).toLocaleDateString();
		this.editedMarketingRights.push(right);
		let indexOfRight = this.marketingRights.indexOf(right);
		this.marketingRights.splice(indexOfRight, 1);
		this.additionalMarketingRights = this.fullMarketingGroupsList
			.filter((mk) => mk.Type === "MarketingRight")
			.filter((mktgrp) => {
				return !this.marketingRights.map((mk) => mk.Name).includes(mktgrp.Name);
			});
	}
	getMarketingRightBadge(right: MarketingGroup) {
		let badge = "";
		if (right) {
			badge = badge + right.Name;
		}
		if (right && right.Status && right.Status.Action) {
			badge = badge + "-" + right.Status.Action;
		}
		if (right && right.Status && right.Status.ActionDate) {
			badge = badge + "-" + new Date(right.Status.ActionDate).toISOString().replace(/^([0-9\-]*)(T.*)/, "$1");
		}
		return badge;
	}
	addMarketingRight(mk: { dist: string; action?: string; date?: string }) {
		if (mk.dist && mk.action && mk.date) {
			this.editedMarketingRights.push({
				Name: mk.dist,
				Type: "MarketingRight",
				ActivateDate: new Date(Date.now()).toLocaleDateString(),
				ExpireDate: null,
				Status: {
					Action: mk.action,
					ActionDate: new Date(mk.date).toLocaleDateString(),
				},
			});
			let rightToReplace = this.marketingRights.filter((rt) => rt.Name === mk.dist)[0];
			if (rightToReplace) {
				this.marketingRights.splice(this.marketingRights.indexOf(rightToReplace), 1);
				this.marketingRights.push({
					Name: mk.dist,
					Type: "MarketingRight",
					ActivateDate: new Date(Date.now()).toLocaleDateString(),
					Status: {
						Action: mk.action,
						ActionDate: new Date(mk.date).toLocaleDateString(),
					},
				});
			} else {
				this.marketingRights.push({
					Name: mk.dist,
					Type: "MarketingRight",
					ActivateDate: new Date(Date.now()).toLocaleDateString(),
					Status: {
						Action: mk.action,
						ActionDate: new Date(mk.date).toLocaleDateString(),
					},
				});
			}
		} else if (mk.dist) {
			this.editedMarketingRights.push({
				Name: mk.dist,
				Type: "MarketingRight",
				ActivateDate: new Date(Date.now()).toLocaleDateString(),
				ExpireDate: null,
			});
			let rightToReplace = this.marketingRights.filter((rt) => rt.Name === mk.dist)[0];
			if (rightToReplace) {
				this.marketingRights.splice(this.marketingRights.indexOf(rightToReplace), 1);
				this.marketingRights.push({
					Name: mk.dist,
					Type: "MarketingRight",
					ActivateDate: new Date(Date.now()).toLocaleDateString(),
					ExpireDate: null,
				});
			} else {
				this.marketingRights.push({
					Name: mk.dist,
					Type: "MarketingRight",
					ActivateDate: new Date(Date.now()).toLocaleDateString(),
					ExpireDate: null,
				});
			}
		} else {
			this.alert.alerts.next({ message: "Please check that all required fields are filled in for Marketing Rights that you are adding" });
		}

		// right.ActivateDate = new Date(Date.now());
		// this.editedMarketingRights.push(right);
		// this.marketingRights.push(right);
	}
	openDocument(url: string) {
		this.formData.openDocumentModal.next(url);
	}
	disableProduct(prod: any) {
		let displayClass = "product-field";
		for (let product of prod) {
			switch (product[0]) {
				case "Marketable":
					product[1] ? (displayClass = null) : (displayClass = "disable-field");
				case "Discount Group":
					product[1] ? (displayClass = null) : (displayClass = "disable-field");
				case "Category":
					product[1] ? (displayClass = null) : (displayClass = "disable-field");
				case "Unit Price":
					product[1] ? (displayClass = null) : (displayClass = "disable-field");
			}
		}
		return displayClass;
	}

	getProductLinePropertyDescription(property: any) {
		const target = property[0].toLowerCase().replace(/saleable/, "salable");

		// Try the PL meta (note: there are still mismatching field names with meta, which we need to fix).
		const match = this.productMeta.filter(plm =>
			plm.Name.toLowerCase() == target
			|| plm.LegacyName.toLowerCase() == target
			|| plm.LegacyName.toLowerCase().replace(/_/g, "") == target
			|| plm.Name.toLowerCase().includes(target)	// Catch-all
		);
		if (match.length && match[0]?.Description) {
			// Replaces the literal \\n in description strings with a title-friendly line break.
			return match[0].Description.replace(/\n|\\n|\\\n|\\\\n/g, "\x0A");
		}
		return "";
	}


	prepBullForm(bull: BullForm) {
		if (bull.Proof.EvalDateStart) {
			let proofDate = new Date(bull.Proof.EvalDateStart);
			this.proofPeriodString = proofDate.toISOString().replace(/^([0-9\-]*)(T.*)/, "$1");
		}
		if (bull.DateOfBirth) {
			let birthdayDate = new Date(bull.DateOfBirth);
			let parsedBirthDay = birthdayDate.toISOString().replace(/^([0-9\-]*)(T.*)/, "$1");

			this.dateOfBirthString = parsedBirthDay;
		}
		if (bull.ReleaseDate !== ("" || null)) {
			bull.ReleaseDate = new Date(bull.ReleaseDate);
		}
		if (!bull.ShortName) {
			bull.ShortName = "";
		}
		this.marketingStatus = this.bull.MarketingGroups.filter((mkt) => mkt.Name === "Interim" || mkt.Name === "NewToLineup")[0] || this.marketingStatus;
		if (this.marketingStatus.Name === "") {
			this.injectedMarketingStatus = true;
		} else {
			this.injectedMarketingStatus = false;
			if (this.marketingStatus.ActivateDate !== ("" || null)) {
				this.marketingStatus.ActivateDate = new Date(this.marketingStatus.ActivateDate);
			}
			if (this.marketingStatus.ExpireDate !== ("" || null)) {
				this.marketingStatus.ExpireDate = new Date(this.marketingStatus.ExpireDate);
			}
		}

		if (!bull.YoungSire) {
			bull.YoungSire = "";
		}
		if (bull.Meta) {
			bull.Meta.AIStatus = bull.Meta.AIStatus || "";
			bull.Meta.BirthState = bull.Meta.BirthState || "";
			bull.Meta.aAa = bull.Meta.aAa || "";
			bull.Meta.DMS = bull.Meta.DMS || "";
			bull.Meta.DnaStatus = bull.Meta.DnaStatus || "";
			bull.Meta.PercentBlack = bull.Meta.PercentBlack || "";
			bull.Meta.Classification = bull.Meta.Classification || "";
			bull.Meta.RHA_Pct = bull.Meta.RHA_Pct || "";
			bull.Meta.RHA_Ind = bull.Meta.RHA_Ind || "";
			bull.Meta.CloneGen = bull.Meta.CloneGen || "";
			bull.Meta.CrossGen = bull.Meta.CrossGen || "";
			bull.Meta.DnaStatus = bull.Meta.DnaStatus || "";
			bull.Meta.HousedInCountry = bull.Meta.HousedInCountry || "";
		} else {
			bull.Meta = {};
			bull.Meta.AIStatus = "";
			bull.Meta.BirthState = "";
			bull.Meta.aAa = "";
			bull.Meta.DMS = "";
			bull.Meta.DnaStatus = "";
			bull.Meta.PercentBlack = "";
			bull.Meta.Classification = "";
			bull.Meta.RHA_Pct = "";
			bull.Meta.RHA_Ind = "";
			bull.Meta.CloneGen = "";
			bull.Meta.CrossGen = "";
			bull.Meta.DnaStatus = "";
		}
		if (!bull.YoungSire) {
			bull.YoungSire = "";
		}
		if (!bull.Genotypes) {
			bull.Genotypes = {};
			bull.Genotypes.BCN = "";
			bull.Genotypes.BLG = "";
			bull.Genotypes.KC = "";
		} else {
			bull.Genotypes.BCN = bull.Genotypes.BCN || "";
			bull.Genotypes.BLG = bull.Genotypes.BLG || "";
			bull.Genotypes.KC = bull.Genotypes.KC || "";
		}
		if (!bull.ProductionStatus) {
			bull.ProductionStatus = {};
			bull.ProductionStatus.Type = "";
			bull.ProductionStatus.State = "";
			bull.ProductionStatus.Location = "";
			bull.ProductionStatus.BarnStatus = "";
			bull.ProductionStatus.BarnCode = "";
		} else {
			bull.ProductionStatus.Type = bull.ProductionStatus.Type || "";
			bull.ProductionStatus.State = bull.ProductionStatus.State || "";
			bull.ProductionStatus.Location = bull.ProductionStatus.Location || "";
			bull.ProductionStatus.BarnStatus = bull.ProductionStatus.BarnStatus || "";
			bull.ProductionStatus.BarnCode = bull.ProductionStatus.BarnCode || "";
		}
		this.ExtractHealthTests(bull);

		this.ExtractDocuments(bull);

		this.bullData = Object.entries(this.bull);

		this.designationList = this.bull.MarketingCampaigns.filter((mktcamp) => mktcamp.Type === "Designation");
		this.indexList = this.bull.MarketingCampaigns.filter((mktcamp) => mktcamp.Type === "Index");

		this.marketingGroups = this.bull.MarketingGroups.filter((mktgrp) => mktgrp.Type === "MarketingGroup");
		this.additionalMarketingGroups = this.fullMarketingGroupsList
			.filter((mk) => {
				return mk.Name === "Japan" || mk.Name === "Saudi";
			})
			.filter((mktgrp) => {
				return !this.marketingGroups.map((mk) => mk.Name).includes(mktgrp.Name);
			});
		this.removableMarketingGroups = this.marketingGroups.filter((mkt) => mkt.Name === "Japan" || mkt.Name === "Saudi");
		this.marketingRights = this.bull.MarketingGroups.filter((mktgrp) => mktgrp.Type === "MarketingRight").sort((m1, m2) => {
			return (m1.Name < m2.Name ? -1 : m1.Name > m2.Name ? 1 : 0);
		});
		this.additionalMarketingRights = this.fullMarketingGroupsList
			.filter((mk) => mk.Type === "MarketingRight")
			.filter((mktgrp) => {
				return !this.marketingRights.map((mk) => mk.Name).includes(mktgrp.Name);
			});

		// Now, assign all the traits.
		this.AssertProofData();
		this.AssertLactationData();

		this.AssertLogoMeta(bull);
		this.AssertOtherIdentifiers(bull);
		this.AssertParentAverage(bull);

		this.setProductLinesArray();
		this.setProductionStatus();
		this.getAvailableStudCodes();
	}


	/**
	 * Extracted from prepBullForm for readability.
	 * Assigns proof data (traits) in the payload to the bull object model for the bullform view.
	 * When this assertion is complete, the traits will have all metadata applied from cache.
	 * Can this assertion be more dynamic (say looping over all categories and applying the traits to the model)?
	 * Yes, but not today.
	 */
	private AssertProofData(): void {
		this.Uncategorized = this.GetCategoryByName(CategoryMap.Uncategorized);
		this.productionTraits = this.GetCategoryByName(CategoryMap.Production).Traits || [];
		this.compositeTraits = this.GetCategoryByName(CategoryMap.Indexes).Traits || [];

		this.wellness = this.GetCategoryByName(CategoryMap.Wellness);
		this.wellnessCDCB = [];
		this.wellnessZoetis = [];
		if (this.wellness.Traits) {
			this.wellnessCDCB = this.wellness.Traits.filter((trait: Trait) => {
				return !trait.LegacyName.match(/^z_/);
			});
			this.wellnessZoetis = this.wellness.Traits.filter((trait: Trait) => {
				if (trait.EvalDateGenomic && !this.evalDateGenomic) {
					// Assign this.evalDateGenomic when present.
					this.evalDateGenomic = " (" + new Date(trait.EvalDateGenomic).toISOString().replace(/^([0-9\-]*)(T.*)/, "$1") + ")";
					// this.evalDateGenomic = trait.EvalDateGenomic;
				}
				return trait.LegacyName.match(/^z_/);
			});
		}

		this.fertilityTabCategories = this.bull.Proof.Categories.filter((cat) => cat.Name === "Sire Fertility" || cat.Name === "Daughter Fertility").map(category => this.GetCategoryByName(category.Name));

		this.management = this.GetCategoryByName(CategoryMap.Management);
		this.managementTraitsArrayOne = [];
		this.managementTraitsArrayTwo = [];
		if (this.management.Traits) {
			this.managementTraitsArrayOne = this.management.Traits.slice(0, this.management.Traits.length / 2);
			this.managementTraitsArrayTwo = this.management.Traits.slice(this.management.Traits.length / 2);
		}

		this.type = this.GetCategoryByName(CategoryMap.Type);
		this.typeTraitsArrayOne = [];
		this.typeTraitsArrayTwo = [];
		if (this.type.Traits) {
			this.typeTraitsArrayOne = this.type.Traits.slice(0, this.type.Traits.length / 2);
			this.typeTraitsArrayTwo = this.type.Traits.slice(this.type.Traits.length / 2);
		}
		this.inbreeding = this.GetCategoryByName(CategoryMap.Inbreeding).Traits || [];
	}


	/**
	 * Assigns daughter/dam/et al milk product data (lactation traits) in the payload to the appropriate object model for the bullform view.
	 * Includes all meta and type (Dam, MGDam, Daughter, MMGDam).
	 */
	private AssertLactationData(): void {
		let milkProduction = {};
		// LactationField
		Object.keys(this.bull.Lactation).forEach(key => {
			milkProduction[key] = this.bull.Lactation[key].map(trait => {
				// Get the trait meta by Id.
				const traitMeta = this.LactationMeta.filter(tm => tm.Id == trait.Id).map(trait => {
					trait.Type = (key.includes("Dam") ? key : trait.Type);
					return trait;
				})[0];
				return { ...trait, ...traitMeta };
			});
		});
		// console.log(milkProduction);
		this.bull.Lactation = milkProduction;
	}


	/**
	 * Refactored out of AssertProofData, plucks the category object from Categories array by name.
	 * @param categoryName
	 * @returns
	 */
	private GetCategoryByName(categoryName: string): Categories {
		const cat = this.bull.Proof.Categories.filter((cat) => cat.Name === categoryName);
		// console.log(categoryName, cat);
		if (cat.length == 0) {
			return new Categories();
		}
		this.AssignMetaToTraits(cat[0]);
		return {...cat[0]};
	}


	/**
	 * Apply trait meta that we already have from IndexDb to trait objects.
	 * @param traits The array of traits, usually associated with a category.
	 * @returns Void, this mutates the category object passed in.
	 */
	private AssignMetaToTraits(category: Categories): void {
		category.Traits = category.Traits.map(trait => {
			// Get the trait meta by Id.
			const traitMeta = this.traitMeta.filter(tm => tm.Id == trait.Id)[0];
			return { ...trait, ...traitMeta };
		});
	}


	/**
	 * Ensures that this.bullParentAverages is an array of trait objects representing parent averages.
	 * @param bull
	 */
	private AssertParentAverage(bull: BullForm): void {
		this.bullParentAverages = bull.Proof.Categories.reduce((result, cat) => {
			let paTraits = cat.Traits.filter(trait => this.isParentAverage(trait));
			if (paTraits.length > 0) {
				cat.Traits = paTraits;
				result.push(cat);
			}
			return result;
		}, []);
	}


	/**
	 * Filters out any standard identifiers that are already shown on the bull form...mainly RegId.
	 * Also associates these with metadata for presentation purposes in the view.
	 * @param bull A BullForm object.
	 */
	private AssertOtherIdentifiers(bull: BullForm): void {
		if (!bull.Identifiers || bull.Identifiers.length == 0)
			return;

		// Apply a filter, we don't want anything domestic, then map to additional meta.
		bull.Identifiers = bull.Identifiers.filter(id => id.Origin != "Animal Registry" && !id.Type.includes("RegId")).map(id => {
			let meta = this.animalMeta.filter( am => id.Origin == am.Country && (am.Name.includes(id.Type)) );
			if (!meta.length) {
				// Try again omitting the country restriction for stragglers that don't yet have the meta for their specific country.
				meta = this.animalMeta.filter(am => (am.Name.includes(id.Type)));
				if (!meta.length)
					return id;
			}
			return {
				Label: meta[0].Label,
				HeaderLabel: meta[0].HeaderLabel,
				Description: meta[0].Description,
				...id
			};
		});

		// Apply a feasible sorting order (Country then Type)
		bull.Identifiers.sort((id1, id2) => id1.Origin.localeCompare(id2.Origin) || id1.Type.localeCompare(id2.Type));
	}


	/**
	 * Assigns the bull object some default MarketingLogoCode meta.
	 * In the absense of bull.Meta.MarketingLogoCode, sets bullLogoMeta.hasLogo to false.
	 */
	private AssertLogoMeta(bull: BullForm): void {
		this.bullLogoMeta.hasLogo = false;
		if (bull.Meta.MarketingLogoCode) {
			this.bullLogoMeta = {
				...this.marketingLogoMap.filter(ml => ml.MfgCode == bull.Meta.MarketingLogoCode)[0],
				hasLogo: true
			};
		}
	}

	/**
	 * Assigns health tests, in order, to the bull object.
	 * Using the healthTestMeta object to fill in the gaps for the view proved problematic since it was passing a reference, not a value.
	 * Now, the healthTestMeta entry is being deep-copied into the bull's HealthTests object.
	 * @param bull
	 */
	private ExtractHealthTests(bull: BullForm) {
		let {healthTestOrder} = this;
		let bullHealthTestNames = bull.HealthTests.map((ht) => ht.TestName);
		let comparisonHealthTestNames = this.healthTestMeta.map((ht) => ht.TestName);
		comparisonHealthTestNames.forEach((ht) => {
			if (!bullHealthTestNames.includes(ht)) {
				// Deep copy this health test into the bull's object so that it doesn't duplicate the healthTestMeta object.
				let healthTestToPush = this.healthTestMeta.filter((htest) => htest.TestName === ht)[0];
				healthTestToPush = JSON.parse(JSON.stringify(healthTestToPush));
				bull.HealthTests.push(healthTestToPush);
			}
		});
		let arrangedHealthTests = [];
		for (let test of healthTestOrder) {
			let health = bull.HealthTests.filter((ht) => ht.LegacyName === test)[0];
			arrangedHealthTests.push(health);
		}
		bull.HealthTests = arrangedHealthTests;
	}

	/**
	 * Extract the documents, if any, from the bull object and apply to this.documents.
	 * Transforms pedigree docs from object of objects into an array of objects.
	 * TODO: Marked for additioanl refactoring.
	 * @param bull The bull object received from the API.
	 */
	private ExtractDocuments(bull: BullForm) {
		let docs = bull.Documents;

		this.documents = Object.entries(docs);
		if (this.documents.length == 0) return;

		let fileArray = Object.entries(this.documents[0][1].Files);

		if (fileArray.length == 0) {
			this.documents[0][1].Files = [];
			return;
		}
		if (fileArray[0][0] !== "0") {
			this.documents[0][1].Files = fileArray;

			if (this.documents[0][1].Files[0]) {
				let files = this.documents[0][1].Files[0][1];
				if (files) {
					this.documents[0][1].Files = files;
				}
			}
		}
	}

	async ngOnInit() {
		this.saveChangesSubscription = this.formData.saveChanges.subscribe((save: boolean) => {
			if (save) {
				this.saveChanges();
			}
			if (!save) {
				this.cancelEditMode();
			}
		});
		let user: User = await this.authService.getUserFromStorage("bull-form");
		this.userCanEdit = user.SecurityLevel > 3;
		this.nextBullSub = this.nextBull.subscribe((bull: BullForm) => {
			this.bull = bull;
		});
		this.bull = await this.formData.getBullFormData(this.startingBull);

		this.prepBullForm(this.bull);
		document.getElementById("search-bar").focus();
	}


	/**
	 * Modified to use the "HeaderLabel" from the Metadata rather than a bunch of reg-ex clauses.
	 * This is unique scenario for trait data labels which usually use the "Label" field.
	 * @param trait The lactation trait.
	 * @returns A readable label.
	 */
	getLactationTraitLabel(trait: LactationField): string {
		let tm = this.LactationMeta.filter(t => t.Id == trait.Id)[0],
			label = tm.HeaderLabel;
		if (trait.hasOwnProperty("ValueKg")) {
			return label.replace(/\bkg$/i, "").concat("LB/KG");
		}
		return label;
	}


	/**
	 * Retrieves the lactation trait value from a given LactationField object based on different properties.
	 * If the trait has a 'FormattedValue', it is returned.
	 * If 'ValueKg' and 'ValueLb' properties exist, it constructs a value using them.
	 * If no suitable value is found, it returns the default 'Value'.
	 * @param {LactationField} trait - The LactationField object containing lactation trait information.
	 * @returns {string} - The lactation trait value to be displayed.
	 */
	getLactationTraitValue(trait: LactationField) {
		return trait.FormattedValue ??
			(trait.ValueKg && trait.ValueLb ? `${trait.ValueLb} / ${trait.ValueKg}` : trait.Value);
	}

	isParentAverage(trait: Trait) {
		if (trait.LegacyName.match(/^pa_.*/)) {
			return true;
		} else {
			return false;
		}
	}

	getTraitValue(trait: Trait) {
		if (trait.Label.match(/\$$|\(\$\)/)) {
			return `$ ${trait.Value}`;
		}
		if (trait.ValueRel) {
			return `${trait.Value} / ${trait.ValueRel}`;
		} else {
			return `${trait.Value}`;
		}
	}
	changeBullData(bull: BullForm) {
		this.bull = bull;
		this.marketingStatus = {
			ExpireDate: "",
			ActivateDate: "",
			Name: "",
			Type: "MarketingGroup",
		};
		this.prepBullForm(this.bull);
	}
	ngOnDestroy() {
		this.nextBullSub.unsubscribe();
		this.saveChangesSubscription.unsubscribe();
	}
	dismissModal() {
		this.editMode = false;
		this.modalController.dismiss();
	}

	async navigateLeft() {
		this.editMode = false;

		let user: User = await this.authService.getUserFromStorage("navigate left");
		let newBull = await this.formData.getBullFormData(this.recall.currentNodeList[this.searchIndex === 0 ? 0 : this.searchIndex - 1].data.Id);
		let bull = newBull;

		if (this.searchIndex === 0) {
			this.changeBullData(bull);
		} else {
			this.searchIndex -= 1;
			this.changeBullData(bull);
		}
	}
	async navigateRight() {
		this.editMode = false;

		let user: User = await this.authService.getUserFromStorage("navigate right");
		let newBull = await this.formData.getBullFormData(this.recall.currentNodeList[this.searchIndex === this.recall.currentNodeList.length - 1 ? this.searchIndex : this.searchIndex + 1].data.Id);
		let bull = newBull;

		if (this.searchIndex === this.recall.currentNodeList.length - 1) {
			this.changeBullData(bull);
		} else {
			this.searchIndex += 1;
			this.changeBullData(bull);
		}
	}

	setCurrentTab(tabName: string) {
		this.editMode = false;

		this.currentTab = tabName;
	}
	async findBull(bullRegId: string, bullShortName?: string) {
		this.matchContainer.nativeElement.style.border = "none";

		this.possibleMatches = [];
		this.editMode = false;

		let newBull = await this.formData.getBullFormData(bullRegId);
		if (newBull.Error) {
			this.alert.alerts.next({ message: `${newBull.Error}` });
			return false;
		} else {
			let bull = newBull;
			bullShortName ? (this.search = bullShortName) : (this.search = bull.ShortName);
			this.changeBullData(bull);
			this.setProductLinesArray();
		}
	}
	getDMS(dms: any) {
		return dms.toString().replace(/(\d{3})(\d{3})/, "$1-$2");
	}
	changeEditMode() {
		this.editMode = !this.editMode;
		if (this.editMode) {
			this.deepCopy = {};
			this.deepCopyMarketingStatus = {};
			this.editedMarketingRights = [];
			this.editedMarketingGroups = [];
			this.deepCopy = JSON.parse(JSON.stringify(this.bull));
			this.deepCopyMarketingStatus = JSON.parse(JSON.stringify(this.marketingStatus));
		} else {
			this.cancelEditMode();
		}
	}


	/**
	 * Returns formatted productline values for render in the view.
	 * Cleaned up to be less hacky, but still needs to refer to the meta.
	 * TODO: Use the meta, it contains formats!
	 * @param property
	 * @returns A product line label.
	 */
	formatProductProperty(property: any[]) {
		if (typeof property[1] === "boolean") {
			if (property[1]) {
				return "Yes";
			} else {
				return "No";
			}
		}
		const priceProperties = ["UnitPrice", "UnitCost", "Cogs", "MinFlex"];
		if (priceProperties.includes(property[0]) && property[1]) {
			if (property[1].toString().match(/^([0-9]{1,3})$/)) {
				return `$ ${property[1]}.00`;
			}
			if (property[1].toString().match(/^([0-9]{1,3})\.([0-9]{1})$/)) {
				return `$ ${property[1]}0`;
			}
			if (property[1].toString().match(/^([0-9]{1,3})\.([0-9]{2})$/)) {
				return `$ ${property[1]}`;
			}
			else {
				return property[1];
			}
		}

		// ATA Stuff.
		if (property[0] == "ATA STAR" && !isNaN(parseFloat(property[1]))) {
			return "* ".repeat(parseFloat(property[1]));
		}

		if (!isNaN(property[1]) && !isNaN(parseFloat(property[1]))) {
			// console.log(property[0], property[1], typeof property[1]);
			return parseFloat(property[1]).toLocaleString();
		}
		// Fall-through, return the raw amount.
		return property[1];
	}


	setProductLinesArray() {
		this.productLineArray = [];
		this.bull.ProductLines.sort((a, b) => {
			return a.DisplayOrder - b.DisplayOrder;
		});
		for (let product of this.bull.ProductLines) {
			let productToAdd: ProductLine = {
				NaabCode: product.NaabCode || "",
				EC: product.LegacyECStatus || "",
				Category: product.Category || "",
				CountryComments: product.CountryComments || "",
				ToBePriced: product.IsToBePriced || "",
				DiscountGroup: product.DiscountGroup || "",
				ActivityDate: product.ActivityDate ? `Set to ${product.DiscountGroup || "Blank"} on  ` + new Date(product.ActivityDate).toISOString().replace(/^([0-9\-]*)(T.*)/, "$1") : "",
				Marketable: product.IsMarketable,
				Published: product.IsPublished,
				UnitPrice: product.UnitPrice,
				UnitCost: product.UnitCost,
				Cogs: product.Cogs,
				ManufacturerCode: product.ManufacturerCode,
				MinFlex: product.MinFlex,
				Allocated: product.IsAllocated,
				Blocked: product.IsBlocked,
				FullEjac: product.IsFullBatchOnly,
				EUSaleableUnits: product.EUSaleableUnits,
				NonEUSaleableUnits: product.NonEUSaleableUnits,
				HasItemCard: product.HasItemCard,
			};
			this.ApplyFertility(productToAdd, product);
			this.productLineArray.push(Object.entries(productToAdd));
		}
		// console.log(this.productLineArray);
	}


	/**
	 * Loops over the product's fertility object and applies the fields to the productToAdd object.
	 * @param productLine The target object to which the value pairs will be assigned.
	 * @param product The source product from which the value pairs are assigned.
	**/
	private ApplyFertility(productLine: ProductLine, product: ProductLine): void {
		if (!product.Fertility)
			return;
		// We already have trait meta from indexDB, use it.

		product.Fertility.forEach(fert => {
			const match = this.traitMeta.filter(t => t.LegacyName.toLowerCase() == fert.LegacyName.toLowerCase());
			let label = (match.length && match[0].Label ? match[0].Label : fert.LegacyName.replace("_", " "));
			productLine[label] = fert.Value;
		});
	}


	navigateToBullPage() {
		if (this.bull.PrimaryNaabCode )
			window.open(`https://ct.wwsires.com/bull/${this.bull.PrimaryNaabCode}/EN`);
		else
			window.open(`https://ct.wwsires.com/bull/${this.bull.RegId}/EN`);
	}
	changeReleaseDate(event: any) {
		this.bull.ReleaseDate = new Date(event.value);
	}
	changeTestDate(event: any, nameOfTest: string) {
		let healtTestToChange = this.bull.HealthTests.filter((test) => test.LegacyName === nameOfTest)[0];
		healtTestToChange.TestDate = new Date(event.value);
	}
	changeMarketingStatusDate(event: any, dateType: string) {
		if (dateType === "start") {
			this.marketingStatus.ActivateDate = new Date(event.value);
		} else {
			this.marketingStatus.ExpireDate = new Date(event.value);
		}
	}
	changeMarketingRightDate(event: any, value: string, whichDate?: string) {
		let mk = this.bull.MarketingGroups.filter((mktr) => mktr.Type === "MarketingRight" && mktr.Name === value)[0];
		if (whichDate === "start") {
			mk.ActivateDate = new Date(event.value);
		}
	}

	cancelEditMode() {
		this.marketingStatus = this.deepCopyMarketingStatus;
		this.editedMarketingRights = [];
		this.editedMarketingGroups = [];
		this.changeBullData(this.deepCopy);

		this.editMode = false;
	}
	confirmChanges() {
		this.formData.openSaveChangesModal.next(true);
	}
	async saveChanges() {
		await this.editBull().finally(() => {
			this.preSavedNaabCodes = [];
		});
		this.editMode = false;
	}

	/**
	 * Attempts to enforce the order of the naab codes prior to saving when multiple Naab codes occupy the 0th position.
	 * When detected, the last 0 found will be bumped.
	 * @returns An array of naab coddes, cleansed, and properly ordered.
	**/
	ApplyNaabCodeOrder(): { DisplayOrder: number; Published: boolean; NaabCode: string; }[] {
		let hasZeroPosition: boolean = false;
		return this.bull.ProductLines.map( item => {
			// If we already have our DisplayOrder 0, bump this one up.
			if ( hasZeroPosition && item.DisplayOrder === 0 )
				item.DisplayOrder++;

			// We have our 0th position.
			if ( item.DisplayOrder === 0 )
				hasZeroPosition = true;

			// Now only return the fields we care about saving.
			return item = {
				"DisplayOrder": item.DisplayOrder,
				"Published": item.Published,
				"NaabCode": item.NaabCode
			};
		});
	}


	async editBull() {
		let bullToSend: BullForm = {};
		let user: User = await this.authService.getUserFromStorage("edit bull");

		if (this.deepCopy.IsDead !== this.bull.IsDead) {
			bullToSend.IsDead = this.bull.IsDead;
		}
		for (let [key, value] of Object.entries(this.bull.Genotypes)) {
			if (this.deepCopy.Genotypes[key] !== this.bull.Genotypes[key]) {
				if (!bullToSend.Genotypes) {
					bullToSend.Genotypes = {};
				}
				bullToSend.Genotypes[key] = value;
			}
		}

		if (this.marketingStatus.Name !== this.deepCopyMarketingStatus.Name) {
			if (this.marketingStatus.ActivateDate === "" || this.marketingStatus.ExpireDate === "") {
				this.cancelEditMode();
				this.alert.alerts.next({ message: `No changes made please check Activate and Expire Dates` });
				return false;
			} else {
				bullToSend.MarketingGroups = [];
				bullToSend.MarketingGroups.push(this.marketingStatus);
			}
		} else if (this.marketingStatus.ActivateDate !== this.deepCopyMarketingStatus.ActivateDate || this.marketingStatus.ExpireDate !== this.deepCopyMarketingStatus.ExpireDate) {
			if (this.marketingStatus.Name === "") {
				this.cancelEditMode();
				this.alert.alerts.next({ message: `No changes made please set a Marketing Status` });
				return false;
			} else {
				bullToSend.MarketingGroups = [];
				bullToSend.MarketingGroups.push(this.marketingStatus);
			}
		}

		for (let [key, value] of Object.entries(this.bull.Meta)) {
			if (this.deepCopy.Meta[key] !== this.bull.Meta[key]) {
				if (!bullToSend.Meta) {
					bullToSend.Meta = {};
				}
				bullToSend.Meta[key] = value;
			}
		}

		if (this.deepCopy.YoungSire !== this.bull.YoungSire) {
			if (this.bull.ReleaseDate === ("" || null)) {
				this.cancelEditMode();
				this.alert.alerts.next({ message: `No changes made please set Proven Release Date` });
				return false;
			} else {
				bullToSend.YoungSire = this.bull.YoungSire;
				if (this.deepCopy.ReleaseDate !== this.bull.ReleaseDate) {
					bullToSend.ReleaseDate = this.bull.ReleaseDate;
				}
			}
		}

		if (this.deepCopy.Recessives !== this.bull.Recessives) {
			bullToSend.Recessives = this.bull.Recessives;
		}

		if (this.deepCopy.HealthTests.filter((ht) => ht.LegacyName === "IBR").map((t) => t.TestResult)[0] !== this.bull.HealthTests.filter((ht) => ht.LegacyName === "IBR")[0].TestResult) {
			if (!bullToSend.HealthTests) {
				bullToSend.HealthTests = [];
			}
			bullToSend.HealthTests.push(
				this.bull.HealthTests.filter((ht) => ht.LegacyName === "IBR").map((health) => {
					health.TestDate = new Date(Date.now());
					return health;
				})[0]
			);
		}
		if (this.deepCopy.HealthTests.filter((ht) => ht.LegacyName === "LEU").map((t) => t.TestResult)[0] !== this.bull.HealthTests.filter((ht) => ht.LegacyName === "LEU")[0].TestResult) {
			if (!bullToSend.HealthTests) {
				bullToSend.HealthTests = [];
			}
			bullToSend.HealthTests.push(
				this.bull.HealthTests.filter((ht) => ht.LegacyName === "LEU").map((health) => {
					health.TestDate = new Date(Date.now());
					return health;
				})[0]
			);
		}
		if (this.deepCopy.HealthTests.filter((ht) => ht.LegacyName === "BT").map((t) => t.TestResult)[0] !== this.bull.HealthTests.filter((ht) => ht.LegacyName === "BT")[0].TestResult) {
			if (!bullToSend.HealthTests) {
				bullToSend.HealthTests = [];
			}
			bullToSend.HealthTests.push(
				this.bull.HealthTests.filter((ht) => ht.LegacyName === "BT").map((health) => {
					health.TestDate = new Date(Date.now());
					return health;
				})[0]
			);
		}
		if (this.deepCopy.HealthTests.filter((ht) => ht.LegacyName === "UDT").map((t) => t.TestResult)[0] !== this.bull.HealthTests.filter((ht) => ht.LegacyName === "UDT")[0].TestResult) {
			if (!bullToSend.HealthTests) {
				bullToSend.HealthTests = [];
			}
			bullToSend.HealthTests.push(
				this.bull.HealthTests.filter((ht) => ht.LegacyName === "UDT").map((health) => {
					health.TestDate = new Date(Date.now());
					return health;
				})[0]
			);
		}
		if (this.deepCopy.HealthTests.filter((ht) => ht.LegacyName === "EHD").map((t) => t.TestResult)[0] !== this.bull.HealthTests.filter((ht) => ht.LegacyName === "EHD")[0].TestResult) {
			if (!bullToSend.HealthTests) {
				bullToSend.HealthTests = [];
			}
			bullToSend.HealthTests.push(
				this.bull.HealthTests.filter((ht) => ht.LegacyName === "EHD").map((health) => {
					health.TestDate = new Date(Date.now());
					return health;
				})[0]
			);
		}
		if (this.deepCopy.HealthTests.filter((ht) => ht.LegacyName === "JOHNES").map((t) => t.TestResult)[0] !== this.bull.HealthTests.filter((ht) => ht.LegacyName === "JOHNES")[0].TestResult) {
			if (!bullToSend.HealthTests) {
				bullToSend.HealthTests = [];
			}
			bullToSend.HealthTests.push(
				this.bull.HealthTests.filter((ht) => ht.LegacyName === "JOHNES").map((health) => {
					health.TestDate = new Date(Date.now());
					return health;
				})[0]
			);
		}
		if (
			this.deepCopy.HealthTests.filter((ht) => ht.LegacyName === "JOHNES-FECAL").map((t) => t.TestResult)[0] !==
			this.bull.HealthTests.filter((ht) => ht.LegacyName === "JOHNES-FECAL")[0].TestResult
		) {
			if (!bullToSend.HealthTests) {
				bullToSend.HealthTests = [];
			}
			bullToSend.HealthTests.push(
				this.bull.HealthTests.filter((ht) => ht.LegacyName === "JOHNES-FECAL").map((health) => {
					health.TestDate = new Date(Date.now());
					return health;
				})[0]
			);
		}

		if (this.deepCopy.BreedSort !== this.bull.BreedSort) {
			bullToSend.BreedSort = this.bull.BreedSort;
		}

		if (this.deepCopy.ShortName !== this.bull.ShortName) {
			bullToSend.ShortName = this.bull.ShortName;
		}

		if (this.deepCopy.RegName !== this.bull.RegName) {
			bullToSend.RegName = this.bull.RegName;
		}

		if (this.deepCopy.Meta.CloneGen !== this.bull.Meta.CloneGen) {
			bullToSend.Meta.CloneGen = this.bull.Meta.CloneGen;
		}

		if (this.editedMarketingRights.length > 0) {
			if (!bullToSend.MarketingGroups) {
				bullToSend.MarketingGroups = [];
			}
			this.editedMarketingRights = this.editedMarketingRights.map((right) => {
				if (!right.ExpireDate || right.ExpireDate === "") {
					right.ExpireDate = null;
				}
				if (!right.ActivateDate || right.ActivateDate === "") {
					right.ActivateDate = null;
				}

				return right;
			});

			this.editedMarketingRights.forEach((rts) => {
				bullToSend.MarketingGroups.push(rts);
			});
		}
		if (this.editedMarketingGroups.length > 0) {
			if (!bullToSend.MarketingGroups) {
				bullToSend.MarketingGroups = [];
			}
			this.editedMarketingGroups = this.editedMarketingGroups.map((group) => {
				if (!group.ExpireDate || group.ExpireDate === "") {
					group.ExpireDate = null;
				}
				if (!group.ActivateDate || group.ActivateDate === "") {
					group.ActivateDate = null;
				}

				return group;
			});

			this.editedMarketingGroups.forEach((rts) => {
				bullToSend.MarketingGroups.push(rts);
			});
		}

		let sendProducts: boolean = false;
		this.deepCopy.ProductLines.forEach( DCProduct => {

			// If we've already set the flag and populated bullToSend.ProductLines, we're done.
			if ( sendProducts )
				return;
			let product = this.bull.ProductLines.filter((prod) => prod.NaabCode === DCProduct.NaabCode)[0];
			if (DCProduct.DisplayOrder !== product.DisplayOrder || DCProduct.Published !== product.Published) {
				sendProducts = true;
				bullToSend.ProductLines = this.ApplyNaabCodeOrder();
			}
		} );

		if (Object.entries(bullToSend).length > 0) {
			bullToSend.Id = this.bull.Id;

			let bull = await this.formData.editBullFormData(bullToSend);
			if (bull && bull.Id) {
				if (bull.Error && bull.Error.message) {
					this.cancelEditMode();
					this.alert.alerts.next({ message: `An Error Occurred, No changes: ${bull.Error.message}` });
				} else {
					this.marketingStatus = {
						ExpireDate: "",
						ActivateDate: "",
						Name: "",
						Type: "MarketingGroup",
					};
					this.changeBullData(bull);
					this.deepCopy = {};
					this.editedMarketingRights = [];
					this.editedMarketingGroups = [];
					this.deepCopyMarketingStatus = {};
					this.formData.changesSaved.next(true);
				}
			} else {
				this.cancelEditMode();
				this.alert.alerts.next({ message: `An error occurred. Please contact IT` });
			}
		} else {
			this.cancelEditMode();
		}
	}
}
