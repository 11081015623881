import { Component, OnInit, OnDestroy } from "@angular/core";
import { environment } from '../environments/environment';
import { ProgressBarService } from "./utils/loading/progress-bar.service";
import { UserManagementService, User } from "./login/user-management.service";
import { Subscription } from "rxjs";
import { BullListRecallService } from "./bull-list/bull-list-recall.service";
import { HttpClient } from "@angular/common/http";
import { BullFormDataService } from "./bull-list/bull-form/bull-form-data.service";
import { BullService } from "./bull/bull.service";
import { NgForage } from "ngforage";
import { LogRocketLicense } from "../environments/environment";
import { Router } from "@angular/router";
import { AuthService } from "./auth/auth.service";

import LogRocket from "logrocket";

interface NavBtn {
	title: string;
	url: string;
	icon: string;
	id: string;
}

@Component({
	selector: "app-root",
	templateUrl: "app.component.html",
	styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
	public userSub: Subscription;
	public user: User;
	public versionNumber: string = "1.15.8.9";
	public selectedIndex = 0;
	public progressValue: number = 0;
	private logRocketLicense: string = LogRocketLicense;
	private IsProductionEnv = environment.production;
	private LogRocketIsLoaded: boolean = false;

	// Objects that represent the links in the Hamburger menu
	public navBtns: NavBtn[] = [
		{
			title: "Home",
			url: "home",
			icon: "home",
			id: "nav-menu-launch-home"
		},
		{
			title: "List Builder",
			url: "bulls",
			icon: "receipt",
			id: "nav-menu-launch-list-builder"
		},
		{
			title: "Document Upload",	// TODO: Hide this for non-WWS users.
			url: "document-upload",
			icon: "document",
			id: "nav-menu-launch-document-upload"
		},
	];
	constructor(
		public http: HttpClient,
		public progress: ProgressBarService,
		public userManager: UserManagementService,
		public authService: AuthService,
		public recall: BullListRecallService,
		public bullFormDataService: BullFormDataService,
		public bullService: BullService,
		public ngf: NgForage,
		public router: Router
	) {
		this.userSub = this.authService.user.subscribe((currentUser: User) => {
			this.user = currentUser;
			this.LoadLogRocket(currentUser);
		});
	}
	// This function will retrieve the Meta and Naab Code Index objects if you delete them from the IndexDB storage in the Dev console. This is strictly for developer troubleshooting at this time,
	// and resolving user issues that require fetching a new version of these objects. Future improvements on functionality are to remove the current objects from IndexDB automatically before calling for the new ones.

	async refreshAppData() {
		this.progress.loading.next({
			load: true,
			source: "root",
			message: "Loading Application Data...",
		});
		await this.ngf.removeItem("Meta");
		await this.ngf.removeItem("NaabCodeIndex");
		await this.ngf.removeItem("BABUser");
		await this.bullFormDataService.getNaabCodeIndex();
		await this.bullService.getMetaObject();

		this.bullService.metaRightCountries =
			this.bullService.applicationMeta.MarketingGroup.filter((mkr) => {
				return mkr.Type === "MarketingRight";
			}).map((mkr) => {
				return mkr.Name.replace(/^([a-zA-Z]+).*/, "$1");
			});
		let countryHolder = new Set(this.bullService.metaRightCountries);
		countryHolder.forEach((country) => {
			this.bullService.uniqueCountries.push(country);
		});

		// Get user by re-validating the token
		await this.authService.getUser();

		// Then setup the user's custom lists
		await this.authService.applyUserSavedLists(this.user);

		setTimeout(() => {
			this.progress.loading.next({ load: false, source: "root end" });
		}, 1000);
	}

	async ngOnInit() {
		this.user = await this.authService.getUserFromStorage();

		if (this.user) {
			this.authService.loggedIn = true;

			// await this.authService.applyUserSavedLists( this.user );

			await this.bullFormDataService.getNaabCodeIndex();

			await this.bullService.getMetaObject();
		}

		// Checking for the presence of NaabCodeIndex and Meta objects comparison with timestamp that is stored with objects is made and a new object is retrieved from
		// the server if more than 24 hours has passed since the last object grab.
	}

	// Launches the bull form from the hamburger menu. calls next on a subject in the recall service that the subscriber in the bull form's component.ts file
	launchBullForm() {
		this.recall.showBullForm.next({ show: true, fromList: false });
	}

	openURL(url: string) {
		window.open(url, "_blank");
	}

	ngOnDestroy() {
		this.userSub.unsubscribe();
	}


	/**
	 * Loads Log Rocket library and identifies the user.
	 * @param {User} user - The user object to validate for LogRocket initialization.
	 */
	private LoadLogRocket(user: User) {
		if (!this.ValidateLogRocket(user)) {
			// At least one of our "don't bother" conditions was met, don't bother loading LogRocket.
			return;
		}
		LogRocket.init(this.logRocketLicense, {
			shouldDebugLog: !this.IsProductionEnv,
			console: {
				isEnabled: false,
			},
			release: this.versionNumber
		});
		LogRocket.identify(user.Id, {
			name: user.FullName,
			email: user.Email
		});
		console.log("LogRocket initialized.");
		this.LogRocketIsLoaded = true;
	}


	/**
	* Validates if LogRocket should be initialized to capture session information for the user.
	* Initializes only once.
	* Only initializes in Production.
	* Ignores Admin sessions, we already know how we behave in the system.
	* @param {User} user - The user object to validate for LogRocket initialization.
	* @returns {boolean} Returns true if LogRocket should be initialized, false otherwise.
	*/
	private ValidateLogRocket(user: User) {
		// If we've already loaded, don't bother again.
		if (this.LogRocketIsLoaded) {
			return false;
		}

		// If the user object is empty (or null), don't bother.
		if (user == null || !user.hasOwnProperty("SecurityLevel")) {
			return false;
		}

		// We're only tracking in Production, don't bother in dev/stage.
		if (!this.IsProductionEnv) {
			return false;
		}

		// Lastly, if the user is running as an admin account, don't bother tracking.
		if (user.SecurityLevel == 10) {
			return false;
		}
		return true;
	}
}
