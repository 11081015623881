import { IPrice } from "../../price/price.model";
import { LactationField } from "../bull-list.model";

export class Sons {
	Id?: string;
	ShortName?: string;
	NaabCode?: string;
	RegId?: string;
	TPI?: number;
	Relationship?: string;
	RegName?: string;
}
export class Categories {
	Traits?: Trait[];
	CategoryOrder?: number;
	Name?: string;
	Source?: Source;
}
export interface BullDocuments {
	Pedigree?: {
		Endpoint: string;
		Files: { Label: string; File: string }[];
	};
	Dna?: {
		Endpoint: string;
		Files: { Label: string; File: string }[];
	};
	Certificate?: {
		Endpoint: string;
		Files: { Label: string; File: string }[];
	};
}
export class Source {
	Abbreviation?: string;
	Name?: string;
	Type?: string;
	Label?: string;
}
export class Proof {
	EvalDateStart?: string;
	EvalDateEnd?: string;
	EvalType?: string;
	Categories?: Categories[];
}
//
// will have to create and pass back interim

export class LineageStack {
	ShortName?: string;
	GlobalId?: string;
	RegId?: string;
	Gender?: string;
	PrimaryNaabCode?: string;
	Relationship?: string;
	RegName?: string;
}
export class MarketingGroup {
	Name?: string;
	Type?: string;
	ActivateDate?: any;
	ExpireDate?: any;
	Status?: {
		Action?: string;
		ActionDate?: any;
	};
}
export class MarketingCampaign extends MarketingGroup {
	Thumbnail?: string;
	Label?: string;
	LegacyName?: string;
}

export interface ProductLine extends IPrice {
	EC?: string;
	FullEjac?: boolean;
	Blocked?: boolean;
	Allocated?: boolean;
	[key: string]: any;
}
export class Trait {
	Trait?: string;
	Format?: any;
	IsPublished?: number;
	DisplayOrder?: number;
	Value?: number;
	Label?: string;
	LabelRel?: string;
	ValueRel?: number;
	Description?: string;
	LegacyName?: string;
	Id: string;
	EvalDateGenomic?: string;
}

export class HealthTest {
	Alias?: string;
	Id?: string;
	TestDate?: string;
	TestName?: string;
	TestResult?: string;
	LegacyName?: string;
}
export class BullFormHealthTest {
	TestDate?: Date;
	TestName?: string;
	TestResult?: string;
	LegacyName?: string;
}

export class BullForm {
	Id?: string;
	RegName?: string;
	Gender?: string;
	ShortName?: string;
	RegId?: string;
	Breed?: string;
	Error?: any;
	BreedSort?: string;
	PrimaryNaabCode?: string;
	Country?: string;
	Recessives?: string;
	Controller?: number;
	HealthTests?: BullFormHealthTest[];
	DateOfBirth?: string;
	ReleaseDate?: Date | string;
	ProductLines?: ProductLine[];
	Stud?: number;
	YoungSire?: string;
	Haplotypes?: string;
	SireStack?: string;
	isActiveMarketable?: boolean;

	DateOfBirthString?: string;
	Sons?: Sons[];
	IsDead?: boolean;
	BullProductionStatus?: string;
	ProductionStatus?: {
		Type?: string;
		MoveDate?: string;
		ResidencyDate?: string;
		IsProduction?: boolean;
		IsEU?: boolean;
		IsIsolation?: boolean;
		IsLayoff?: boolean;
		BarnCode?: string;
		BarnStatus?: string;
		State?: string;
		Location?: string;
	};
	Proof?: {
		EvalDateStart?: string;
		EvalDateEnd?: string;
		EvalType?: string;
		Categories?: Categories[];
		EvalCountry?: string;
	};
	Lactation?: {
		Dam?: LactationField[];
		DaughterAvg?: LactationField[];
		MGDam?: LactationField[];
		MMGDam?: LactationField[];
	};
	Meta?: {
		HousedInCountry?: string;
		AIStatus?: string;
		BirthState?: string;
		aAa?: string;
		DMS?: string;
		DnaStatus?: string;
		Classification?: string;
		PercentBlack?: string;
		CloneGen?: string;
		CrossGen?: string;
		PureBred?: boolean;
		RHA_Pct?: string;
		RHA_Ind?: string;
		ConvertedCanadianDaughterAvg?: boolean;
		[key: string]: any;
	};
	Documents?: BullDocuments;

	Photos?: {
		Sires?: string[];
		Daughters?: string[];
	};
	Genotypes?: {
		BLG?: string;
		KC?: string;
		BCN?: string;
	};
	Lineage?: LineageStack[];
	MarketingCampaigns?: MarketingCampaign[];
	MarketingGroups?: MarketingGroup[];
	Identifiers?: any;
}
